import { type ParsedToken } from "firebase/auth";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    clientId: null as string | null,
    claims: null as ParsedToken | null,
    resetKeyAvatar: 0,
  }),
  getters: {
    isAdmin(state): boolean {
      return !!state.claims?.admin;
    },
    isClient(state): boolean {
      const { clientId } = state.claims ?? {};
      return !!clientId;
    },
  },
  actions: {
    setClientId(clientId: string | null) {
      this.clientId = clientId;
    },
    async setClaims(claims: ParsedToken | null) {
      this.claims = claims;
    },
  },
});
