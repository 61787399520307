import { defineStore } from "pinia";

export const useToast = defineStore("toast", {
  state: () => ({
    show: false,
    type: "success" as "success" | "error",
    text: null as string | null,
    duration: 3000,
    isClosable: false,
  }),
  actions: {
    showSuccessToast(message?: string) {
      this.show = true;
      this.type = "success";
      this.text = message ?? "Succès";
      this.duration = 2000;
      this.isClosable = false;
    },
    showErrorToast(message?: string) {
      this.show = true;
      this.type = "error";
      this.text = message ?? "Erreur";
      this.duration = 10000;
      this.isClosable = true;
    },
  },
});
