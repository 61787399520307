import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { fr } from "vuetify/locale";
import { createVuetify } from "vuetify";
import * as directives from "vuetify/directives";
import colors from "vuetify/util/colors";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  directives,
  locale: {
    locale: "fr",
    messages: { fr },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#00A9BA",
          success: "#00A9BA",
          warn: "#D75A00",
          error: colors.red.base,
        },
      },
    },
  },
  defaults: {
    VBtn: {
      variant: "flat",
    },
    VAutocomplete: {
      variant: "outlined",
      density: "compact",
      color: "primary",
    },
    VSelect: {
      variant: "outlined",
      density: "compact",
    },
    VTextField: {
      variant: "outlined",
      density: "compact",
    },
    VAlert: {
      variant: "tonal",
      density: "compact",
      border: true,
      color: "primary",
    },
  },
});
