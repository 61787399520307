import App from "./App.vue";
import { VueFire, VueFireAuth } from "vuefire";
import { firebaseApp } from "./firebase";

import { createApp } from "vue";
import * as Sentry from "@sentry/vue";

import vuetify from "./vuetify";
import pinia from "./store";
import router from "./router";
import "core-js/features/array/to-sorted";
import "unfonts.css";
import "./styles/main.scss";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://963243079a0a2c75b3cb0d9f7c6a3ddf@o4506735387410432.ingest.sentry.io/4506735388786688",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.feedbackIntegration({
      showBranding: false,
      showName: false,
      triggerLabel: "Aide / Nous contacter",
      submitButtonLabel: "Envoyer",
      messagePlaceholder: "Décrivez le bug ou votre suggestion.",
      cancelButtonLabel: "Annuler",
      addScreenshotButtonLabel: "Ajouter une capture d'écran",
      isRequiredLabel: "(requis)",
      formTitle: "Contact",
    }),
  ],
  tracesSampleRate: 1.0,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.VITE_ENV || "production",
});

app
  .use(vuetify)
  .use(router)
  .use(pinia)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()],
  })
  .mount("#app");
