<template>
  <v-snackbar
    v-model="toast.show"
    location="top right"
    :timeout="toast.duration"
    :close-on-back="false"
    :color="color"
  >
    <slot>
      {{ toast.text }}
    </slot>
    <template #actions>
      <v-btn v-if="toast.isClosable" variant="text" @click="toast.show = false">
        Fermer
      </v-btn>
      <v-icon v-if="toast.type === 'success'">mdi-check</v-icon>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useToast } from "@/store";

const toast = useToast();

const color = computed(() => (toast.type === "error" ? "error" : "success"));
</script>
